
import {defineComponent, ref} from "vue";
import Multiselect from "@vueform/multiselect";
import CodeHighlighter2 from "@/view/content/highlighters/CodeHighlighter2.vue";

export default defineComponent({
  name: "example-4",
  components: {
    Multiselect,
    CodeHighlighter2,
  },
  setup() {
    const example4 = ref({
      mode: "tags",
      value: ["batman"],
      options: [
        { value: "batman", label: "Batman" },
        { value: "robin", label: "Robin" },
        { value: "joker", label: "Joker" },
      ],
      searchable: true,
      createTag: true,
    });

    const htmlCode = ` <Multiselect v-model="example4.value" v-bind="example4"></Multiselect>`;

    const jsCode = `const example4 = ref({
      mode: "tags",
      value: ["batman"],
      options: [
        { value: "batman", label: "Batman" },
        { value: "robin", label: "Robin" },
        { value: "joker", label: "Joker" }
      ],
      searchable: true,
      createTag: true
    });`;

    return {
      example4,
      htmlCode,
      jsCode,
    };
  },
});
